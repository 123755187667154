<template>
    <div class="cont-password" :key="langKey">
        <div class="cont-title-password">
            <p v-if="entities.length > 0" class="title-password">{{texts.new}}</p>
            <p v-else class="title-password">{{texts.title}}</p>
        </div>
        <form class="form-password" @submit.prevent="newPass">
            <div v-if="entities.length > 0">
                <span v-html="texts.newAccount"></span>
            </div>
            <div v-if="entities.length > 0">
                <label for="entityVal">{{texts.entities}}</label>
                <select  :required="true" name="entityVal" id="entityVal" v-model="entitySelected">
                  <option :value="null" disabled hidden>{{ texts.entitySelected}}</option>
                   <option v-for="entity in entities" :value="entity.id" >{{ entity.description }}</option>
                </select>
            </div>  
            <div>
                <label for="name">{{texts.name}}</label>
                <input type="text" name="name" id="name" v-model="lastname" disabled>
            </div>
            <div>
                <label for="firstname">{{texts.firstname}}</label>
                <input type="text" name="firstname" id="firstname" v-model="firstname" disabled>
            </div>
            <div>
                <label for="mailVal">{{texts.mail}}</label>
                <input type="mail" name="mailVal" id="mailVal" v-model="contactEmail" disabled>
            </div>
            <div>
                <label for="loginVal">{{texts.login}}</label>
                <input v-if="logins.length==1" type="text" name="loginVal" id="loginVal" disabled :value="logins[0]">
                <select v-else :required="true" name="loginVal" id="loginVal" v-model="loginSelected">
                  <option :value="null" disabled hidden>{{ texts.loginSelected}}</option>
                   <option v-for="login in logins" >{{ login }}</option>
                </select>
            </div>    
            <p class="text-password-val">{{texts.text}}</p>
            <div>
                <label for="password">{{texts.password}}</label>
                <div>
                    <fa id="pass-eye" class="eyes" :icon="['fas', 'eye']" @click="showPass"/>
                    <fa id="pass-eye-slash" class="eyes display-none" :icon="['fas', 'eye-slash']" @click="dontShowPass"/>
                    <input type="password" name="password" id="password" v-bind:placeholder="texts.password" v-model="password" :class="{ inputerror : !newPassIsValid }">
                </div>
            </div>
            <div>
                <label for="verify">{{texts.verify}}</label>
                <div>
                    <fa id="verify-eye" class="eyes" :icon="['fas', 'eye']" @click="showVerify"/>
                    <fa id="verify-eye-slash" class="eyes display-none" :icon="['fas', 'eye-slash']" @click="dontShowVerify"/>
                    <input type="password" name="verify" id="verify" v-bind:placeholder="texts.password" v-model="verify" :class="{ inputerror : !verifyIsValid }">
                </div>
            </div>
            <button type="submit" :disabled="!formIsValid" >{{texts.submit}}</button>
        </form>
    </div>
    <div id="wrapper-password-chge" :key="langKey" v-if="loading" >
        <ellipsis style="position: absolute; top: 50%;left: 50%;" />
    </div>
    <confirm-dialogue ref="confirmDialogue" />

</template>

<script>
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Ellipsis from '@/components/Ellipsis.vue';

export default {
    components: {
        ConfirmDialogue,
        Ellipsis,
    },
    data(){
        return{
            texts:{
                title: null,
                name: null,
                firstname: null,
                mail: null,
                login: null,
                loginSelected: null,
                text: null,
                password: null,
                verify: null,
                submit: null,
            },
            commonTexts: {},
            langstore: 0,
            profile: {},
            password: "",
            verify: "",
            firstname: "",
            lastname: "",
            contactEmail: "",
            login: "",
            logins: [],
            loginSelected: null,
            entities: [],
            entitySelected: null,
            loading: false,
        };
    },
    mounted(){

        // requête pour textes selon langue
        this.getTexts();

        // vérif token en query
        var body = {};
        if (this.$route.query.token) {
            body = {token: this.$route.query.token};
        }
        body = JSON.stringify(body);
        fetch(this.$baseUrl('/api/validtoken'),
        {method: 'POST', body: body})
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            this.lastname = data.familyName;
            this.firstname = data.givenName;
            this.contactEmail = data.contactEmail
            this.logins = data.login;
            if (this.logins.length == 1) {
                this.loginSelected = this.logins[0];
            }
            if (data.entities) {
                this.entities = data.entities;
            }
        })
        .catch((error) => {
            this.$refs.confirmDialogue.show({
                title: this.texts.error,
                message: `${error}`
            });
        });
    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        // "!!" convertie en boolean pour tester si champ est rempli
        newPassIsValid(){
            return !!this.password && (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,}$/.test(this.password))
        },

        verifyIsValid(){
            return !!this.verify && (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,}$/.test(this.verify)) && this.verify === this.password;
        },

        formIsValid(){
            return this.newPassIsValid && this.verifyIsValid && this.loginSelected && (this.entities.length == 0 || this.entitySelected)
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
        loginSelected(newval,old) {
          console.log(newval);

          if (newval == '') return "Please Select a prefered login";
          else return newval;
        }
    },
    methods: {

        showPass(){
            document.getElementById("password").setAttribute("type","text");
            document.getElementById("pass-eye").classList.add("display-none");
            document.getElementById("pass-eye-slash").classList.remove("display-none");
        },

        dontShowPass(){
            document.getElementById("password").setAttribute("type","password");
            document.getElementById("pass-eye-slash").classList.add("display-none");
            document.getElementById("pass-eye").classList.remove("display-none");
        },

        showVerify(){
            document.getElementById("verify").setAttribute("type","text");
            document.getElementById("verify-eye").classList.add("display-none");
            document.getElementById("verify-eye-slash").classList.remove("display-none");
        },

        dontShowVerify(){
            document.getElementById("verify").setAttribute("type","password");
            document.getElementById("verify-eye-slash").classList.add("display-none");
            document.getElementById("verify-eye").classList.remove("display-none");
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].valpass;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        },

        newPass(){
          var body = {};
          if (this.$route.query.token) {
            body = {token: this.$route.query.token}
          }
          if (this.entitySelected) {
            body.entity = this.entitySelected; 
          }
          body = JSON.stringify(body);
          let auth = btoa(`${this.loginSelected}:${this.password}`);
          this.loading =  true;
          fetch(this.$baseUrl('/api/password'),
            {method: 'PUT', body: body,
            headers: {'Authorization': `Basic ${auth}`}})
            .then((response) => {
                if (!response.ok) {
                    const error = new Error ('Request Error',{cause: response});
                    throw error
                }
                else {
                    this.$refs.confirmDialogue.show({
                        title: this.commonTexts.generic,
                        message: this.commonTexts.genericAnswer
                    })
                    .finally((dialog) => {
                        this.loading =  false;
                        this.$router.push("/");
                    })
                    .catch((dialog) => {
                        this.loading =  false;
                        this.$router.push("/");
                    });
                }
            })
            .catch((error) => {
                this.loading =  false;
                error.cause.text().then((text)=> {
                    this.$refs.confirmDialogue.show({
                        title: this.texts.submitError,
                        message: `${text}`
                    });
                })
            });
        },
    },
};

</script>



<style lang="scss" >

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";



</style>
